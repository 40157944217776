<template>
  <entity-form
    :id="partner ? partner.id : null"
    :module-name="moduleName"
    :model="form"
    :automatic-reload="automaticReload"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-group label="Profit">
      <b-form-input
        v-model.number="form.profit"
        min="0"
        max="100"
      />
    </b-form-group>

    <b-form-group label="First profit">
      <b-form-input
        v-model.number="form.first_profit"
        min="0"
        max="100"
      />
    </b-form-group>

    <b-form-group label="Min amount for payout">
      <b-form-input
        v-model.number="form.min_payout"
        min="0"
      />
    </b-form-group>

    <b-form-group label="Payout after (days)">
      <b-form-input
        v-model.number="form.payout_after"
        min="0"
      />
    </b-form-group>

    <b-form-group label="Max discount codes:">
      <b-form-input
        v-model.number="form.max_discount_codes"
        min="0"
      />
    </b-form-group>

    <b-form-group label="Giveaway codes after (days) (0 -> giveaways are disabled)">
      <b-form-input
        v-model.number="form.giveaway_after"
        min="0"
      />
    </b-form-group>

    <b-form-group label="Affiliate custom key:">
      <b-form-input
        v-model="form.aff_custom_key"
      />
    </b-form-group>

    <b-form-group label="Referral custom key:">
      <b-form-input
        v-model="form.referral_custom_key"
      />
    </b-form-group>
  </entity-form>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    EntityForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    partner: {
      required: false,
      type: Object,
    },
    moduleName: String,
    automaticReload: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      form: {
        profit: this.partner ? this.partner.profit : 0,
        first_profit: this.partner ? this.partner.first_profit : 0,
        min_payout: this.partner ? this.partner.min_payout : 0,
        payout_after: this.partner ? this.partner.payout_after : 0,
        max_discount_codes: this.partner ? this.partner.max_discount_codes : 0,
        giveaway_after: this.partner ? this.partner.giveaway_after : 0,
        referral_custom_key: this.partner ? this.partner.referral_custom_key : '',
        aff_custom_key: this.partner ? this.partner.aff_custom_key : '',
      },
    }
  },
  methods: {
    beforeSubmit() {
      if (this.form.referral_custom_key.length < 4 || this.form.referral_custom_key.length > 32 || this.form.aff_custom_key.length < 4 || this.form.aff_custom_key.length > 32) {
        Swal.fire({
          title: 'Custom keys must have more than 3 and less then 33 characters.',
          icon: 'warning',
        })

        return false
      }

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
