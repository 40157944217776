<template>
  <div>
    <gql-table
      ref="partnersGqlTable"
      :fields="fields"
      :query="query"
      :actions="actions"
      query-type="partners"
      sort-by="createdAt"
      :sort-desc="true"
      @edit="onEdit"
      @delete="onDelete"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'partner-detail', params: { id: data.item.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>
      <template #cell(affiliate_type)="data">
        <b-badge
          :variant="partnerTypesVariant[data.item.affiliate_type].variant"
          class="m-25"
        >
          {{ partnerTypesVariant[data.item.affiliate_type].label }}
        </b-badge>
        <b-badge
          v-if="!data.item.user.isPartner"
          class="m-25"
          variant="light-danger"
        >
          DELETED
        </b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge
          v-if="data.item.state"
          :variant="partnerStateVariants[data.item.state].variant"
        >
          {{ partnerStateVariants[data.item.state].label }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>
    </gql-table>

    <b-modal
      id="partnerModal"
      title="Partner edit"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <partner-form
        :automatic-reload="false"
        :partner="partnerEdit"
        module-name="partnerList"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BCol, BLink, BRow, BBadge,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import { getUserExtraTags } from '@/plugins/userUtils'
import {
  PARTNER_STATES, PARTNER_STATES_VARIANTS, PARTNER_TYPES_ENUM, PARTNER_TYPES_VARIANTS,
} from '@/enums/affiliate'
import { formatDateTime } from '@/plugins/formaters'
import PartnerForm from '@/components/forms/partner/PartnerForm.vue'
import store from '@/store'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Remove',
    icon: 'TrashIcon',
    emit: 'delete',
  },
]

const fields = [
  {
    name: 'user',
    label: 'User',
    filterable: {
      type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
    },
  },
  {
    name: 'affiliate_type', label: 'Affiliate type', filterable: { type: 'multienum', enum: PARTNER_TYPES_ENUM },
  },
  {
    name: 'aff_custom_key', label: 'Aff key', filterable: 'like',
  },
  {
    name: 'referral_custom_key', label: 'Referral key', filterable: 'like',
  },
  {
    name: 'state', label: 'State', filterable: { type: 'multienum', enum: PARTNER_STATES },
  },
  {
    name: 'createdAt',
    label: 'Created at',
  },
]

const query = [
  'id',
  'affiliate_type',
  'aff_custom_key',
  'referral_custom_key',
  'state',
  'createdAt',
  {
    user: {
      fields: [
        'firstName',
        'lastName',
        'id',
        'email',
        'kyc_at',
        'createdAt',
        'isPartner',
        { country: { fields: ['name', 'code2'] } },
        { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }],
    },
  },
]
export default {
  components: {
    PartnerForm,
    BRow,
    BCol,
    TagsList,
    BLink,
    GqlTable,
    BBadge,
  },
  data() {
    return {
      fields,
      query,
      actions,
      partnerTypesVariant: PARTNER_TYPES_VARIANTS,
      partnerStateVariants: PARTNER_STATES_VARIANTS,
      partnerEdit: null,
    }
  },
  computed: {
    ...get('partnerList@row', { partner: 'partner' }),
  },
  methods: {
    getUserExtraTags,
    formatDateTime,
    onEdit(item) {
      dispatch('partnerList/get', item.id).then(() => {
        this.partnerEdit = this.partner
        this.$bvModal.show('partnerModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('partnerModal')
      this.partnerEdit = null
      this.$refs.partnersGqlTable.reloadAll(true)
    },
    onDelete(item) {
      Swal.fire({
        title: 'Remove this partner',
        text: 'Are you sure you want to remove this row?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('partnerList/delete', { id: item.id, autoReload: false })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'Partner was successfully removed',
                  variant: 'success',
                },
              })
              this.$refs.partnersGqlTable.reloadAll(true)
            })
        }
      })
    },
  },
}
</script>
